// core/calendar

@use "../../node_modules/sass-styler/styler" as *;
@use "../../node_modules/@wordpress/base-styles/colors" as color;

// default mode (for differences between theme, print and editors)
$mode: null !default;

@mixin block-core-calendar {

	table {
		text-align: center;
		font-family: get($styles, font-family);
		font-size: get($styles, font-size);
	}

	thead .pad,
	tbody .pad {
		background: color.$light-gray-300;
	}

	tfoot a {

		@include declare-map(get($styles, link));
	}
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-calendar {

		@include block-core-calendar();
	}
}
