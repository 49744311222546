// alignment classes

@if $mode == theme or $mode == print or $mode == editor {

	.has-text-align-left {
		text-align: left;
	}

	.has-text-align-center {
		text-align: center;
	}

	.has-text-align-right {
		text-align: right;
	}
}


@if $mode == theme {

	.content > *,
	.content [class*="__inner-container"] > * {
		margin-top: $spacing;
		margin-bottom: $spacing;

		&:not(.alignwide):not(.alignfull):not(.alignleft):not(.alignright) {

			@include container(normal);
		}

		&.alignwide {

			@include container(wide);
		}
	}

	.alignfull {
		position: relative;
		width: 100%;
		max-width: 100vw;

		[class*="__inner-container"] > & {
			max-width: 100%;
		}
	}

	.aligncenter {

		&,
		img {
			margin-left: auto;
			margin-right: auto;
		}
	}

	.alignleft,
	.alignright {
		margin-right: get($container, margin);
		margin-left: get($container, margin);

		@include media(stacked) {
			float: none !important;
			max-width: calc(100% - #{2 * get($container, margin)}) !important;
		}

		@include media(unstacked) {

			&,
			&.wp-block-cover-image,
			&.wp-block-cover {
				max-width: calc(300px - #{get($container, margin)});
			}
		}
	}

	.alignleft {
		float: left;

		@include media(wide) {
			margin-left: calc(50% - #{0.5 * get($container, wide)});
		}
	}

	.alignright {
		float: right;

		@include media(wide) {
			margin-right: calc(50% - #{0.5 * get($container, wide)});
		}
	}
}

@if $mode == editor {

	.editor-styles-wrapper .block-editor-block-list__block:not(.is-reusable) {
		margin-top: $spacing;
		margin-bottom: $spacing;
	}

	.wp-block {
		max-width: get($container, normal);
	}

	.wp-block[data-align="wide"] .wp-block[data-align="wide"],
	.wp-block[data-align="full"] .wp-block[data-align="wide"] {
		max-width: get($container, wide);
	}

	.wp-block .wp-block[data-type="core/group"]:not([data-align="full"]):not([data-align="wide"]):not([data-align="left"]):not([data-align="right"]),
	.wp-block .wp-block[data-type="core/cover"]:not([data-align="full"]):not([data-align="wide"]):not([data-align="left"]):not([data-align="right"]) {
		margin-left: auto;
		margin-right: auto;
		max-width: get($container, normal);
	}

	.wp-block .wp-block[data-align="full"],
	.wp-block.is-reusable {
		margin-left: 0;
		margin-right: 0;
	}

	.wp-block[data-align="wide"] {
		max-width: get($container, wide);
	}

	.wp-block[data-align="full"],
	.wp-block.is-reusable {
		max-width: none;
	}

	.wp-block[data-align="right"],
	.wp-block[data-align="left"] {
		max-width: get($container, wide);

		@include media(stacked) {
			height: auto !important;
		}

		> .is-block-content {

			&,
			.wp-block-cover,
			.wp-block-cover-image {
				width: 100%;
				max-width: 290px;
			}

			@include media(stacked) {
				float: none !important;

				&,
				.wp-block-cover,
				.wp-block-cover-image {
					max-width: 100% !important;
				}
			}
		}
	}

	.wp-block[data-align="right"] > .is-block-content {
		margin-left: get($container, margin) !important;
	}

	.wp-block[data-align="left"] > .is-block-content {
		margin-right: get($container, margin) !important;
	}

	@if remtopx($spacing) <= 28px {
		$inserter-size: if(remtopx($spacing) >= 20px, remtopx($spacing), 20px);

		.block-editor-inserter {
			line-height: 1;
		}

		.block-editor-block-list__insertion-point button {
			max-width: $inserter-size;
			max-height: $inserter-size;

			svg {
				max-width: $inserter-size;
				max-height: $inserter-size;
			}
		}

		.block-editor-block-list__layout .block-editor-block-list__block::before {
			top: -0.5 * $spacing;
			right: -0.5 * $spacing;
			bottom: -0.5 * $spacing;
			left: -0.5 * $spacing;
		}
	}
}

@if $mode == print {

	@include spacing();
}

@if $mode == classic-editor {

	* {
		margin: 0;
	}

	body {

		@include spacing();
	}

}
