// core/quote

@mixin block-core-quote {
	padding-#{script-direction()}: get($styles, indent);
	border: 0;
	border-#{script-direction()}: get($styles, quote border, 1px solid rgba(c(base), 0.5));
}

@mixin block-core-quote-p {
	font-size: 1em;
}

@mixin block-core-quote-cite {
	display: block;
	margin-top: 0.5em;
	font-size: 1em;
	font-style: italic;
}

@if $mode == theme or $mode == print {

	.wp-block-quote {

		@include block-core-quote();

		p {

			@include block-core-quote-p();
		}

		cite {

			@include block-core-quote-cite();
		}
	}
}

@if $mode == editor {

	.wp-block-quote {

		@include block-core-quote();

		p {

			@include block-core-quote-p();
		}

		.wp-block-quote__citation {

			@include declare-map(get($styles, cite));
			@include block-core-quote-cite();
		}
	}
}
