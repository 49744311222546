// blob/icon

@mixin block-blob-icon() {
	text-align: center;

	svg {
		max-width: 100%;
		width: 1.5em;
		height: 1.5em;
		line-height: 1.5em;
	}
}

@if $mode == theme or $mode == print or $mode == editor {

	.blob-svg-icon {
		display: inline-flex;
	}

	.wp-block-blob-icon {

		@include block-blob-icon();

		.wp-block-image figcaption {

			@include declare-map($styles, figcaption);
		}
	}
}

@if $mode == editor {

	.blob-component-svg-icon-select {
		margin: 0;
		padding: 0;
	}

	.blob-component-svg-icon-search {
		max-width: 100%;

		.block-editor-inserter__search {
			padding: 16px 16px 14px;
		}

		@at-root .components-panel__body > & {
			padding: 0;

			.block-editor-inserter__search {
				margin: 0 0 4px;
				padding: 0;
			}

			.block-editor-inserter__results {
				padding: 0;
			}
		}
	}
}
