// core/cover

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-cover__inner-container {
		padding-top: 2em;
		padding-bottom: 2em;

		@include media(unbroken) {
			padding-top: 3em;
			padding-bottom: 3em;
		}
	}
}

@if $mode == editor {

	.wp-block[data-type="core/cover"]:not(.has-child-selected) {

		.block-list-appender {
			display: none;
		}
	}
}

@if $mode == theme or $mode == print {

	.wp-block-cover {

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		& + & {
			margin-top: 0;
		}

		.wp-block-group.is-style-grey-background + &,
		.wp-block-group.is-style-blue-background + &,
		.wp-block-group.is-style-dark-blue-background + &,
		.wp-block-group.is-style-purple-background + & {
			margin-top: 0;
		}
	}
}
