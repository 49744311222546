// normalize base styles

@if $mode == theme {

	@include set-styles($styles, true, html);

	ul ul,
	ol ul {
		list-style-type: disc;
	}

	ul,
	ol {
		overflow: hidden;
	}

	#{select(spacing)} {
		margin: 0;
	}
}

@if $mode == editor {

	.editor-styles-wrapper {

		#{select(spacing)} {
			margin: 0;
		}

		p {
			font-size: inherit;
			line-height: inherit;
		}

		li,
		dd {
			margin-top: 0;
			margin-bottom: 0;
		}

		ul,
		ol {
			overflow: hidden;
			padding: inherit;
			padding-#{$script-direction()}: get($styles, indent);
		}

		ul,
		ul ul,
		ol ul {
			list-style-type: disc;
		}

		ol {
			list-style-type: decimal;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin: 0;
			color: inherit;
		}

		hr {
			box-sizing: content-box;
			height: 0;

			overflow: visible;

			border: 0;
		}

		figure {
			margin: 0;
			padding: 0;
		}

		table,
		th,
		td {
			font: inherit;
		}

		caption {
			caption-side: bottom;
		}

		table {
			border-collapse: collapse;
			text-align: inherit;
			border-spacing: 0;
		}

		th,
		thead td {
			text-align: inherit;
		}

		tfoot th,
		tfoot td {
			text-align: inherit;
		}

		@include set-styles(
			remove(
				$styles,
				button, label, fieldset, legend, optgroup, input, input-file, input-multiline, textarea, selection
			)
		);
	}
}

@if $mode == classic-editor {

	@include set-styles($styles, true, "html, body");
}

@if $mode == print {

	// prevent breaking borders when box is longer than page
	* {
		box-decoration-break: clone;
	}

	// prevent breaking blocks of text
	dd,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	p {
		orphans: 2;
		widows: 2;
	}

	// prevent page breaks in inappropriate places
	a,
	blockquote,
	dl,
	figure,
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	img,
	ol,
	pre,
	table,
	ul {
		page-break-inside: avoid;
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	img {
		page-break-after: avoid;
	}

	dl,
	ol,
	ul {
		page-break-before: avoid;
	}

	// links
	a {
		page-break-inside: avoid;
	}

	a[href]::after {
		content: " < " attr(href) " > ";
		font-size: 0.8em;
	}

	a::after > img {
		content: "";
	}

	article a[href^="#"]::after {
		content: "";
	}

	// hide elements not useful for print
	audio,
	form.search,
	iframe,
	ins,
	.menu,
	nav,
	video {
		display: none;
	}

	@include set-styles(
		merge(
			$styles,
			(
				line-height: 1.5,
				heading: (
					font-weight: 700
				)
			)
		),
		false,
		"html"
	);
}
