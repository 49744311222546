// blob/cards

@mixin block-blob-card() {
	display: flex;
	flex-direction: column;
	align-items: stretch;

	@include text-variation-small();

	> * {
		width: 100%;
	}
}

@mixin block-blob-card-header() {
	background: c(grey);

	@include responsive-ratio();

	img {

		@include position(0, 0, 0, 0, absolute, 100%, 100%);
	}

	.blob-svg-icon {
		transform: translate3d(50%, -50%, 0);

		@include position(50%, 50%, auto, auto, absolute, 80%, 80%);
	}
}

@mixin block-blob-card-main() {
	flex-grow: 100;
	margin-top: 0.5 * $spacing;
}

@mixin block-blob-card-meta() {
	margin-top: 0.5 * $spacing;
	color: rgba(c(base), 0.5);
}

@mixin block-blob-card-description() {
	margin-top: 0.5 * $spacing;
}

@mixin block-blob-card-footer() {
	margin-top: 0.5 * $spacing;
}

@if $mode == theme or $mode == print {

	.wp-block-blob-card {

		@include block-blob-card();
	}

	.wp-block-blob-card__header {

		@include block-blob-card-header();
	}

	.wp-block-blob-card__main {

		@include block-blob-card-main();
	}

	.wp-block-blob-card__meta {

		@include block-blob-card-meta();
	}

	.wp-block-blob-card__description {

		@include block-blob-card-description();
	}

	.wp-block-blob-card__footer {

		@include block-blob-card-footer();
	}
}

@if $mode == editor {

	[data-type="blob/card"] .wp-block-blob-card {

		@include block-blob-card();
	}

	[data-type="blob/card"] .wp-block-blob-card__header {

		@include block-blob-card-header();

		.image-or-icon,
		.components-placeholder {
			min-height: 0;

			@include position(0, 0, 0, 0, absolute !important, 100%, 100%);
		}

		.components-placeholder__label,
		.components-placeholder__fieldset .components-button {
			margin: 0 2px 2px 0;
		}

		.components-placeholder.is-medium .components-placeholder__fieldset .components-button,
		.components-placeholder.is-small .components-placeholder__fieldset .components-button {
			margin-right: auto;
		}

		.components-placeholder.is-medium .components-button,
		.components-placeholder.is-small .components-button {
			height: 28px;
		}
	}

	[data-type="blob/card"] .wp-block-blob-card__main {

		@include block-blob-card-main();
	}

	[data-type="blob/card"] .wp-block-blob-card__meta {

		@include block-blob-card-meta();
	}

	[data-type="blob/card"] .wp-block-blob-card__description {

		@include block-blob-card-description();
	}

	[data-type="blob/card"] .wp-block-blob-card__footer {

		@include block-blob-card-footer();
	}
}
