// core/separator

@mixin block-core-separator {
	max-width: 300px;
}

@if $mode == theme or $mode == print {

	.wp-block-separator:not(.alignwide):not(.alignfull) {

		@include block-core-separator();
	}
}

@if $mode == editor {

	.wp-block-separator:not([data-align="wide"]):not([data-align="full"]) {

		@include block-core-separator();
	}
}
