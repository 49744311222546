// blob/testimonial

@mixin block-blob-tesimonial-blockquote() {
	padding: 0;
	border: 0;
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-testimonial {

		blockquote {

			@include block-blob-tesimonial-blockquote();
		}

		cite {
			display: block;
			margin-top: 0.5 * $spacing;
			opacity: 0.75;
		}
	}
}

@if $mode == editor {

	[data-type="blob/testimonial"] .wp-block-blob-testimonial blockquote {

		@include block-blob-tesimonial-blockquote();
	}
}
