@use "sass:list";
@use "sass:meta";
@use "is-property" as *;
@use "is-state" as *;

/// Declare properties via sass maps
/// @example
///   @include declare-map((color: red, margin: 0));                  /* color: red; margin: 0; */
///   @include declare-map((color: red, hover: (color: blue)));       /* color: red; &:hover { color: blue; } */
///   @include declare-map((color: red, hover-focus: (color: blue))); /* color: red; &:hover, &:focus { color: blue; } */
/// @param {Map} $map [()] Property declaration
/// @param {Boolean} $strict [true] Whether to check for valid html
/// @group utility

@mixin declare-map($map: (), $strict: true) {
  @each $key, $value in $map {
    @if $key and $map {
      @if meta.type-of($value) == map {
        @if is-state($key) or list.index(hover-focus disabled-readonly, $key) {
          $selector: "&";

          @if list.index(after before first-letter first-line selection, $key) {
            $selector: "&::#{$key}";
          }

          @else {
            $selector: "&:#{$key}";
          }

          @if $key == hover-focus {
            $selector: "&:hover, &:focus";
          }

          @if $key == disabled-readonly {
            $selector: "&:disabled, &:readonly";
          }

          #{$selector} {
            @include declare-map($value, $strict);
          }
        }
      }

      @else {
        @if not $strict or is-property($key) {
          #{$key}: $value;
        }
      }
    }

    @else if meta.type-of($value) != map {
      @if not $strict or is-property($key) {
        #{$key}: $value;
      }
    }
  }
}
