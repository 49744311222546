// core/code

@mixin block-core-code {
	padding: 1em;
	background: c(base, $lightness: 95);
}

@if $mode == theme {

	.wp-block-code {

		@include block-core-code();
	}
}

@if $mode == print {

	.wp-block-code {
		padding: 1em;
		border: 1px solid currentColor;
	}
}

@if $mode == editor {

	.wp-block-code {
		border-radius: 0;
		border: 0;

		@include declare-map(get($styles, code));
		@include block-core-code();

		.block-editor-plain-text {
			display: block;
			padding: 0;
			background: transparent;
			border-radius: 0;
			font-size: inherit;
			font-family: inherit;
		}
	}
}
