// core/rss

@mixin block-core-rss {
	list-style: none;
	padding: 0;

	> li + li {
		margin-top: 16px;
	}
}

@if $mode == theme or $mode == print {

	.wp-block-rss {

		@include block-core-rss();
	}
}

@if $mode == editor {

	.block-editor .wp-block-rss {

		@include block-core-rss();
	}
}

