// blob/icon-text

@mixin block-blob-icon-text() {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	@include cover-link();

	.wp-block-cover &,
	.wp-block-group.is-style-dark-blue & {

		@include color-change();
	}
}

@mixin block-blob-icon-text-header() {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	margin-right: 0.25 * $gap;

	svg {
		width: 1.5em;
		height: 1.5em;
		line-height: 1em;
		fill: c(primary);
	}

	.wp-block-cover &,
	.wp-block-group.is-style-dark-blue & {

		svg {
			fill: c(white);
		}
	}
}

@mixin block-blob-icon-text-main() {
	flex-grow: 100;
}

@if $mode == theme or $mode == print {

	.wp-block-blob-icon-text {

		@include block-blob-icon-text();

		.wp-block-blob-icon-text__header {

			@include block-blob-icon-text-header();
		}

		.wp-block-blob-icon-text__main {

			@include block-blob-icon-text-main();

			> * + * {
				margin-top: $spacing;
				margin-bottom: 0;
			}
		}

		+ .wp-block-blob-icon-text {
			margin-top: 0.5 * $spacing;
		}
	}
}

@if $mode == editor {

	.wp-block-blob-icon-text,
	.wp-block[data-type="blob/icon-text"] {
		margin-top: 0 !important;

		@include block-blob-icon-text();

		.wp-block-blob-icon-text__header {

			@include block-blob-icon-text-header();
		}

		.wp-block-blob-icon-text__main {

			@include block-blob-icon-text-main();

			.block-editor-inner-blocks {
				margin-top: -1 * $spacing;
				margin-bottom: -1 * $spacing;
			}
		}
	}

	.wp-block[data-type="blob/icon-text"] + .wp-block[data-type="blob/icon-text"] {
		margin-top: -0.5 * $spacing !important;
	}
}
