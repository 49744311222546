// typography

@if $mode == theme {

	.has-small-font-size {

		@include text-variation-small();
	}

	.has-normal-font-size {

		@include text-variation-normal();
	}

	.has-large-font-size {

		@include text-variation-large();
	}

	.has-huge-font-size {

		@include text-variation-huge();
	}
}

@if $mode == editor {

	.editor-styles-wrapper .has-small-font-size {

		@include text-variation-small();
	}

	.editor-styles-wrapper .has-normal-font-size {

		@include text-variation-normal();
	}

	.editor-styles-wrapper .has-large-font-size {

		@include text-variation-large();
	}

	.editor-styles-wrapper .has-huge-font-size {

		@include text-variation-huge();
	}
}

@if $mode == theme or $mode == print {

	#{select(heading)} {

		b,
		strong {
			font-weight: 600;
		}
	}

	@include media(unstacked) {

		h1 {
			font-size: 4em;
		}
	}
}

@if $mode == editor {

	.editor-styles-wrapper {

		#{select(heading)} {

			b,
			strong {
				font-weight: 600;
			}
		}

		@include media(unstacked) {

			h1 {
				font-size: 4em;
			}
		}
	}
}
