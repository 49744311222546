// core/gallery

@mixin block-core-gallery {

	ul.blocks-gallery-grid {
		margin-bottom: -16px;
	}
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-gallery {

		@include block-core-gallery();

		&.is-style-logos {

			figure {
				justify-content: center;
			}

			.blocks-gallery-grid {
				justify-content: center;

				li {
					flex-grow: 0;
				}
			}
		}
	}
}
