// blob/steps


@mixin block-blob-steps() {
	list-style-type: none;
	padding: 0;
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1em;

	> li {
		display: flex;
		align-items: center;
		counter-increment: index;

		@include pseudo(counter(index)) {
			display: block;
			width: 4rem;
			margin-right: 1rem;
			padding: 1rem;
			flex-shrink: 0;
			text-align: center;
			background: c(grey);
			font-weight: 700;
			font-size: 1.5em;
			line-height: 1;
		}
	}

	@include media(unstacked) {

		grid-template-columns: 1fr 1fr;
	}
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-steps ul {

		@include block-blob-steps();
	}
}

