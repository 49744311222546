/// Style options to set up default element styles with
/// @group helper

$styles: () !default;

@use "../utility/declare-map" as *;
@use "../utility/get" as *;
@use "../utility/remove" as *;
@use "../utility/set" as *;
@use "normalize" as *;
@use "script-direction" as *;

/// Set default styles for common elements
/// @param {Map} $styles [$styles] Style settings map
/// @param {Boolean} $normalize [false] Whether to also normalize
/// @param {String} $root-selector ["&"] Selector to add root styles to
/// @example
///   @use "../node_modules/sass-styler/styler";
///   styler.styles: (
///     font-size: 18px,
///     font-family: Arial, sans-serif;
///     color: #333;
///     link: (
///       color: blue,
///       text-decoration: none,
///       hover-focus: (
///         text-decotaion: underline
///       )
///     ),
///     heading: (
///       font-weight: 700
///     ),
///     h1: (
///       font-size: 3em
///     ),
///     input: (
///       border: 1px solid #333
///     )
///   );
///   @include set-styles();
/// @group helper

@mixin set-styles($styles: $styles, $normalize: false, $root-selector: "&") {

  @if $normalize {
    // skip rules for normalize if they will be set for styles anyway
    $skip-normalize: ( _name: skip-normalize );
    @each $path in (box-sizing, min-height, vertical-align, bold font-weight, small font-size, code font-size, code font-family, indent, hr, h1 font-size, fieldset padding, fieldset border, label display, input display, input padding, caption caption-side, table-cell padding) {
      @if get($styles, $path) {
        $skip-normalize: set($skip-normalize, $path, null);
      }
    }
    @include normalize($skip-normalize);
  }

  // root styles
  #{$root-selector} {
    @include declare-map($styles);
  }

  // bold elements
  b,
  strong,
  dt {
    @include declare-map(get($styles, bold));
  }

  // italic elements
  em,
  dfn,
  var {
    @include declare-map(get($styles, italic));
  }

  // underline elements
  ins,
  u {
    @include declare-map(get($styles, underline));
  }

  // line-through elements
  del,
  s {
    @include declare-map(get($styles, line-through));
  }

  // small
  small {
    @include declare-map(get($styles, small));
  }

  // subscript
  sub {
    @include declare-map(get($styles, sub));
  }

  // superscript
  sup {
    @include declare-map(get($styles, sup));
  }

  // code elements
  code,
  kbd,
  pre,
  samp {
    @include declare-map(get($styles, code));
  }

  // mark
  mark {
    @include declare-map(get($styles, mark));
  }

  // abbr with title
  abbr[title] {
    @include declare-map(get($styles, abbr));
  }

  // indent
  blockquote,
  q,
  ul,
  ol,
  dt {
    padding-#{script-direction()}: get($styles, indent);
  }

  // blockquote and quote
  blockquote,
  q {
    @include declare-map(get($styles, quote));
  }

  // cite
  cite {
    @include declare-map(get($styles, cite));
  }

  // img
  img {
    @include declare-map(get($styles, img));
  }

  // figure
  figure {
    @include declare-map(get($styles, figure));
  }

  // figcaption
  figcaption {
    @include declare-map(get($styles, figcaption));
  }


  // horizontal ruler
  hr {
    border-top: get($styles, hr);
  }

  // headings
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @include declare-map(get($styles, heading));
  }

  // h1
  h1 {
    @include declare-map(get($styles, h1));
  }

  // h2
  h2 {
    @include declare-map(get($styles, h2));
  }

  // h3
  h3 {
    @include declare-map(get($styles, h3));
  }

  // h4
  h4 {
    @include declare-map(get($styles, h4));
  }

  // h5
  h5 {
    @include declare-map(get($styles, h5));
  }

  // h6
  h6 {
    @include declare-map(get($styles, h6));
  }

  // list
  ul,
  ol {
    @include declare-map(get($styles, list));
  }

  // link
  a {
    @include declare-map(get($styles, link));
  }

  // dialog
  dialog {
    @include declare-map(get($styles, dialog));
  }

  // fieldset
  fieldset {
    @include declare-map(get($styles, fieldset));
  }

  // legend
  legend {
    @include declare-map(get($styles, legend));
  }

  // optgroup
  optgroup {
    @include declare-map(get($styles, optgroup));
  }

  // label
  label {
    @include declare-map(get($styles, label));
  }

  // input
  input:not([type]),
  [type="color"],
  [type="date"],
  [type="datetime"],
  [type="datetime-local"],
  [type="email"],
  [type="image"],
  [type="month"],
  [type="number"],
  [type="password"],
  [type="search"],
  [type="tel"],
  [type="text"],
  [type="time"],
  [type="url"],
  [type="week"],
  select,
  textarea {
    @include declare-map(get($styles, input));
  }

  // file input
  [type="file"] {
    @include declare-map(get($styles, input-file));
  }

  // multiline inputs
  textarea,
  select[multiple] {
    @include declare-map(get($styles, input-multiline));
  }

  // textarea
  textarea {
    @include declare-map(get($styles, textarea));
  }

  // select
  select:not([multiple]) {
    @include declare-map(get($styles, select));
  }

  // standardize option in browsers
  select[multiple] option {
    padding: get($styles, input padding);
  }

  // clickable inputs
  [type="checkbox"],
  [type="radio"] {
    outline: get($styles, input outline);

    label > &,
    > label {
      margin-#{script-direction()}: get($styles, input gap);
    }
  }

  // button
  button,
  [type="button"],
  [type="reset"],
  [type="submit"] {
    @include declare-map(get($styles, button));
  }

  // summary
  summary {
    @include declare-map(remove(get($styles, button), display));
  }

  // caption
  caption {
    @include declare-map(get($styles, caption));
  }

  // table
  table {
    @include declare-map(get($styles, table));
  }

  // table cell
  th,
  td {
    @include declare-map(get($styles, table-cell));

    // table odd column
    &:nth-child(2n + 1) {
      @include declare-map(get($styles, table-odd-column));
    }

    // table even column
    &:nth-child(2n) {
      @include declare-map(get($styles, table-even-column));
    }
  }

  // odd/even table rows
  tr {
    // table odd row
    &:nth-child(2n + 1) td,
    &:nth-child(2n + 1) th {
      @include declare-map(get($styles, table-odd-row));
    }

    // table even row
    &:nth-child(2n) td,
    &:nth-child(2n) th {
      @include declare-map(get($styles, table-even-row));
    }
  }

  // thead
  thead {
    @include declare-map(get($styles, thead));
  }

  // table head
  th,
  thead td {
    @include declare-map(get($styles, table-head));
  }

  // tfoot
  tfoot {
    @include declare-map(get($styles, tfoot));
  }

  // table foot
  tfoot th,
  tfoot td {
    @include declare-map(get($styles, table-foot));
  }

  // selection
  ::-webkit-selection {
    @include declare-map(get($styles, selection));
  }

  ::-moz-selection {
    @include declare-map(get($styles, selection));
  }

  ::selection {
    @include declare-map(get($styles, selection));
  }

  input::-webkit-selection {
    @include declare-map(get($styles, selection));
  }

  input::-moz-selection {
    @include declare-map(get($styles, selection));
  }

  input::selection {
    @include declare-map(get($styles, selection));
  }

  textarea::-webkit-selection {
    @include declare-map(get($styles, selection));
  }

  textarea::-moz-selection {
    @include declare-map(get($styles, selection));
  }

  textarea::selection {
    @include declare-map(get($styles, selection));
  }
}
