// core/media-text

@mixin block-core-media-text {
	gap: $gap;

	@include media(stacked) {
		display: flex;
		flex-direction: column;

		> * {
			width: 100%;
		}
	}
}

@mixin block-core-media-text-content {
	padding-right: 0;
	padding-left: 0;
}

@if $mode == theme or $mode == print {

	.wp-block-media-text {

		@include block-core-media-text();
	}

	.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
		min-height: 10em;
	}

	.wp-block-media-text .wp-block-media-text__content {

		@include block-core-media-text-content();

		> * {
			margin: 0;
		}

		> * + * {
			margin-top: $spacing;
		}

		@include media(stacked) {
			order: 10;
		}
	}
}

@if $mode == editor {

	.wp-block-media-text {

		@include block-core-media-text();
	}

	.wp-block-media-text .block-editor-inner-blocks {

		@include block-core-media-text-content();

		> .block-editor-block-list__layout {
			margin-top: -1 * $spacing;
			margin-bottom: -1 * $spacing;
		}
	}

	.wp-block-media-text.is-image-fill figure.wp-block-media-text__media {
		min-height: 2em;
	}

	[data-type="core/media-text"]:not(.has-child-selected) .block-list-appender {
		display: none;
	}
}

// styles with background

@if $mode == theme or $mode == print {

	.wp-block-media-text.is-style-grey-background,
	.wp-block-media-text.is-style-blue-background,
	.wp-block-media-text.is-style-purple-background {
		gap: 0;

		.wp-block-media-text__content {
			padding: 2em;

			@include media(wide) {
				padding: 3em;
			}
		}
	}

	.wp-block-media-text.is-style-grey-background {
		background: c(grey);
	}

	.wp-block-media-text.is-style-blue-background {
		background: c(primary, 90);
	}

	.wp-block-media-text.is-style-purple-background {
		background: c(secondary, 90);
	}
}

@if $mode == editor {

	.wp-block-media-text.is-style-grey-background,
	.wp-block-media-text.is-style-blue-background,
	.wp-block-media-text.is-style-purple-background {
		background: c(grey);
		gap: 0;

		> .block-editor-inner-blocks {
			padding: 2em;

			@include media(wide) {
				padding: 3em;
			}
		}
	}

	.wp-block-media-text.is-style-grey-background {
		background: c(grey);
	}

	.wp-block-media-text.is-style-blue-background {
		background: c(primary, 90);
	}

	.wp-block-media-text.is-style-purple-background {
		background: c(secondary, 90);
	}

	[data-type="core/block"].is-reusable {

		.block-library-block__reusable-block-container {
			margin: (-1 * $spacing) 0;
		}

		.block-list-appender {
			display: none;
		}
	}
}
