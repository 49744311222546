// popup

@if $mode == theme {

	.popup {
		display: none;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		background: c(primary, -80);
		padding: get($container, margin);

		@include position(0, 0, 0, 0, fixed, 100%, 100%, 150);

		&.is-open {
			display: flex;
			animation: fade-in #{get($transition, duration)} ease-in;

			@include keyframes(fade-in) {

				0% {
					opacity: 0;
				}

				100% {
					opacity: 1;
				}
			}
		}

		&__main {
			max-width: 50em;
			padding: 2em;
			background: c(primary, -80);

			@include media(unstacked) {
				padding: 3em;

			}
		}

		&__actions {

			@include position($spacing, $spacing);

			@at-root .with-admin-bar & {
				top: 46px;
			}
		}

		&__close {
			font-size: 0;
			transition-property: opacity;

			@include svg-bg-icon(close, c(primary), 2rem);

			&:hover,
			&:focus {
				opacity: 0.8;
			}
		}
	}
}

@if $mode == print {

	.popup {
		display: none !important;
	}
}
