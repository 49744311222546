// core/latest-comments

@mixin block-core-latest-comments {
	padding: 0;
}

@if $mode == theme or $mode == print {

	.wp-block-latest-comments {

		@include block-core-latest-comments();
	}
}

@if $mode == editor {

	.editor-styles-wrapper .wp-block-latest-comments {

		@include block-core-latest-comments();
	}
}
