// core/audio

@mixin block-core-audio {

	audio {
		width: 100%;
	}
}

@if $mode == theme or $mode == editor or $mode == print {

	.wp-block-audio {

		@include block-core-audio();
	}
}

@if $mode == editor {

	// reset default styles for consistency

	.wp-block-audio figcaption {
		margin: 0;
		font-size: get($styles, figcaption font-size, inherit);
		text-align: get($styles, figcaption text-align, inherit);

		@include declare-map(remove(get($styles, figcaption), font-size, text-align));
	}
}
