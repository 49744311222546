// blob/phone

@mixin block-blob-phone() {
	position: relative;
	display: block;
	padding-left: 1.75em;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@include cover-link();

	@include svg-bg-icon(phone, c(base), 1.5em) {

		@include position(0, auto, auto, 0);
	}
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-phone {

		@include block-blob-phone();
	}
}

@if $mode == theme or $mode == print {

	.wp-block-blob-phone a {
		text-decoration: none;
		color: inherit;
	}
}

@if $mode == editor {

	.editor-styles-wrapper .wp-block-blob-phone a {
		text-decoration: none;
		color: inherit;
	}
}
