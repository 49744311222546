// blob/pricing-table


@mixin block-blob-pricing-item() {
	padding: $gap;
	background: rgba(#000, 0.05);
	text-align: center;
}

@mixin block-blob-pricing-item-title() {
	margin: 0;
	opacity: 0.5;
	text-transform: uppercase;
}

@mixin block-blob-pricing-item-price() {
	margin: 0;
	font-size: 3em;
	font-family: get($styles, heading font-family);
	font-weight: get($styles, heading font-weight);
}

@mixin block-blob-pricing-item-benefits() {
	margin: 1em 0;

	@include unstyled-list();
}

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-pricing-table .wp-block-blob-pricing-item {

		@include block-blob-pricing-item();
	}

	.wp-block-blob-pricing-table .wp-block-blob-pricing-item__title {

		@include block-blob-pricing-item-title();
	}

	.wp-block-blob-pricing-table .wp-block-blob-pricing-item__price {

		@include block-blob-pricing-item-price();
	}


}

@if $mode == theme or $mode == print {

	.wp-block-blob-pricing-table .wp-block-blob-pricing-item__benefits {

		@include block-blob-pricing-item-benefits();
	}
}

@if $mode == editor {

	.editor-styles-wrapper ul.wp-block-blob-pricing-item__benefits {
		padding: 0;

		@include block-blob-pricing-item-benefits();
	}

	.wp-block-blob-pricing-item .block-editor-block-list__layout {
		display: flex;
		justify-content: center;
	}
}
