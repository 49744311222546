// blob/icon-list


@mixin block-blob-icon-list() {
	display: grid;
	grid-template-columns: 1fr;
	grid-gap: 1em;

	@include media(unstacked) {

		grid-template-columns: 1fr 1fr;
	}
}

@if $mode == theme or $mode == print {

	.wp-block-blob-icon-list {

		@include block-blob-icon-list();
	}
}

@if $mode == editor {

	.wp-block[data-type="blob/icon-list"] {

		> div > .block-editor-inner-blocks > .block-editor-block-list__layout {

			@include block-blob-icon-list();
		}

		@include media(unstacked) {

			.block-list-appender {
				grid-column: span 2;
			}
		}

		&:not(.has-child-selected) {

			.block-list-appender {
				display: none;
			}
		}
	}
}
