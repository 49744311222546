// blob/icon-list-item

@mixin block-blob-icon-list-item() {
	position: relative;
	display: flex;
	align-items: center;
	width: 100%;

	@include cover-link();
}

@mixin block-blob-icon-list-item-header() {
	width: 4em;
	margin-right: 1em;
	padding: 1em;
	flex-shrink: 0;
	background: c(grey);

	svg {
		width: 2em;
		height: 2em;
	}
}

@mixin block-blob-icon-list-item-main() {
	flex-grow: 100;
}

@if $mode == theme or $mode == print {

	.wp-block-blob-icon-list-item {

		@include block-blob-icon-list-item();

		.wp-block-blob-icon-list-item__header {

			@include block-blob-icon-list-item-header();
		}

		.wp-block-blob-icon-list-item__main {

			@include block-blob-icon-list-item-main();
		}
	}
}

@if $mode == editor {

	.wp-block-blob-icon-list-item,
	.wp-block[data-type="blob/icon-list-item"] {
		margin-top: 0 !important;

		@include block-blob-icon-list-item();

		.wp-block-blob-icon-list-item__header {

			@include block-blob-icon-list-item-header();
		}

		.wp-block-blob-icon-list-item__main {

			@include block-blob-icon-list-item-main();
		}
	}
}
