// core/latest-posts

@mixin block-core-latest-posts {
	padding: 0;

	&:not(.is-grid) > li + li {
		margin-top: 16px;
	}

	&.is-grid {
		margin-bottom: -16px;
	}
}

@if $mode == theme or $mode == print {

	.wp-block-latest-posts {

		@include block-core-latest-posts();
	}
}

@if $mode == editor {

	.block-editor .wp-block-latest-posts {

		@include block-core-latest-posts();
	}
}

