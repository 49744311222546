// core/categories

@mixin block-core-categories {
	padding-#{script-direction()}: get($styles, indent);
}

@if $mode == theme or $mode == print {

	ul.wp-block-categories {

		@include block-core-categories();
	}
}

@if $mode == editor {

	.block-editor .wp-block-categories ul {

		@include block-core-categories();
	}
}
