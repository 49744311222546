// set font sizes for different screen sizes

@use "../../node_modules/sass-styler/styler" as *;
@use "../_config" as *;

@mixin text-variation-small() {

	@include declare-map(get($text-variations, small));
}

@mixin text-variation-normal() {

	@include declare-map(get($text-variations, normal));
}

@mixin text-variation-medium() {

	@include declare-map(get($text-variations, medium));
}

@mixin text-variation-large() {

	@include declare-map(get($text-variations, large));
}


@mixin text-variation-huge() {

	@include declare-map(get($text-variations, huge));
}
