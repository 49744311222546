// swiper-container

@import "../../node_modules/swiper/swiper";

@if $mode == theme or $mode == print or $mode == editor {

	.blob-swiper-container {
		display: flex;

		.swiper-slide {
			align-self: center;
			height: auto;
			text-align: center;
		}

		.swiper-button {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			flex-shrink: 0;
			flex-grow: 0;
			width: 2em;
			outline: 0;

			span {
				display: none;
			}

			&.swiper-button-disabled {
				opacity: 0.5;
			}

			&.swiper-button-prev {

				@include svg-bg-icon(chevron-left, c(base), 2em);
			}

			&.swiper-button-next {

				@include svg-bg-icon(chevron-right, c(base), 2em);
			}
		}

		.swiper-pagination {
			display: none;
		}
	}
}
