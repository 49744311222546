// core/embed

@mixin block-core-embed-centered {

	.wp-block-embed__wrapper {
		max-width: 480px;
		margin-right: auto;
		margin-left: auto;
	}

	&.alignleft .wp-block-embed__wrapper {
		margin-left: 0;
	}

	&.alignright .wp-block-embed__wrapper {
		margin-right: 0;
	}
}

@mixin block-core-embed-video {

	.wp-block-embed__wrapper {

		@include responsive-ratio();

		iframe {

			@include position(0, 0, 0, 0, absolute, 100%, 100%);
		}
	}
}

@if $mode == theme or $mode == editor or $mode == print {

	.wp-block-embed-twitter,
	.wp-block-embed-facebook,
	.wp-block-embed-instagram {

		@include block-core-embed-centered();
	}
}

@if $mode == theme or $mode == print {


	.wp-block-embed-youtube {

		@include block-core-embed-video();
	}
}

@if $mode == editor {

	// reset default styles for consistency

	.wp-block-embed figcaption {
		margin: 0;
		font-size: get($styles, figcaption font-size, inherit);
		text-align: get($styles, figcaption text-align, inherit);

		@include declare-map(remove(get($styles, figcaption), font-size, text-align));
	}
}
