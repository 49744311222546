/// Default spacing
/// @group helper

$spacing: null !default;

/// Spacing mode
/// @group helper

$spacing-mode: vertical !default;

@use "sass:list";
@use "../utility/owl" as *;

/// Set vertical spacing between elements in a container
/// @param {Number} $space [null] The space size
/// @param {String} $mode [null] The mode for the spacing (vertical meaning top and bottom, top, bottom, owl)
/// @param {String|List} $selector [(h1,h2,h3,h4,h5,h6,p,ul,ol,blockquote,dl,dt,address,figure,pre,table,dorm,details)] Selectors to apply the spacing to
/// @group helper

@mixin spacing(
  $space: null,
  $mode: null,
  $selector: (h1, h2, h3, h4, h5, h6, p, ul, ol, blockquote, dl, dt, address, figure, pre, table, dorm, details)
) {
  $owl-selector: ();
  $space: if($space, $space, $spacing);
  $mode: if($mode, $mode, $spacing-mode);

  @if $mode == owl {
    $owl-selector: owl($selector);
  }

  @if $mode == vertical or $mode == top or $mode == bottom or $mode == owl {
    #{$selector} {
      margin-top: if($mode != vertical and $mode != top, 0, null);
      margin-bottom: if($mode != vertical and $mode != bottom, 0, null);
    }

    #{if($mode == owl, $owl-selector, $selector)} {
      margin-top: if($mode != bottom, $space, null);
      margin-bottom: if($mode == bottom or $mode == vertical, $space, null);
    }
  }
}
