// core/group

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-group.is-style-grey-background,
	.wp-block-group.is-style-blue-background,
	.wp-block-group.is-style-dark-blue-background,
	.wp-block-group.is-style-purple-background {
		margin-bottom: 0;
		padding: 2em;
		background: c(grey);

		&:first-child {
			margin-top: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}

		@include media(wide) {
			padding: 3em;
		}

		& + &,
		.wp-block-cover + & {
			margin-top: -1 * $spacing;
		}
	}

	.wp-block-group.is-style-blue-background {
		background: c(primary, -80);
	}

	.wp-block-group.is-style-dark-blue-background {
		background: c(primary);
	}

	.wp-block-group.is-style-purple-background {
		background: c(secondary, -80);
	}
}
