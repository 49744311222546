// change color in a container

@use "../../node_modules/sass-styler/styler" as *;
@use "../_config" as *;

@mixin color-change($color: c(white), $hover-color: c(primary)) {

	#{select("&" heading link)} {
		color: $color;
	}

	a:hover,
	a:focus {
		color: $hover-color;
	}

	svg {
		fill: $color;
	}
}
