// blob/accordion

@mixin block-blob-accordion() {

	> * + * {
		margin-top: $spacing;
	}
}

@mixin block-blob-accordion-item-header() {
	padding: get($styles, button padding);
	background-color: get($styles, button background-color);
	color: get($styles, button color);
	cursor: pointer;

	#{select(heading)} {
		position: relative;
		padding-right: 1.75em;
		font-family: get($styles, font-family);
		font-size: get($styles, font-size, 1em);
		font-weight: get($styles, font-weight, 400);
		font-style: get($styles, font-style, normal);
		user-select: none;
		cursor: pointer;

		@include svg-bg-icon(plus, get($styles, button color), 1em) {

			@include position(0, 0, auto, auto, absolute);
		}
	}
}

@mixin block-blob-accordion-item-content() {
	padding: get($styles, button padding);
	overflow: hidden;
}

@if $mode == theme or or $mode == print {

	.wp-block-blob-accordion {

		@include block-blob-accordion();
	}

	.wp-block-blob-accordion-item__header {

		@include block-blob-accordion-item-header();
	}

	.wp-block-blob-accordion-item__main {

		@include block-blob-accordion-item-content();

		> * + * {
			margin-top: $spacing;
			margin-bottom: 0;
		}
	}
}

@if $mode == theme {

	.wp-block-blob-accordion-item__main {
		display: none;
	}

	.wp-block-blob-accordion-item.is-open .wp-block-blob-accordion-item__header {

		#{select(heading)} {

			@include svg-bg-icon(minus, get($styles, button hover color, get($styles, button color)));
		}
	}

	.wp-block-blob-accordion-item.is-open .wp-block-blob-accordion-item__main {
		display: block;
	}
}

@if $mode == editor {

	[data-type="blob/accordion"] {

		.wp-block-blob-accordion {

			@include block-blob-accordion();
		}

		.wp-block-blob-accordion-item__header {

			@include block-blob-accordion-item-header();
		}

		.wp-block-blob-accordion-item.is-open .wp-block-blob-accordion-item__header {

			#{select(heading)} {

				@include svg-bg-icon(minus, get($styles, button hover color, get($styles, button color)));
			}
		}

		.wp-block-blob-accordion-item__main {

			@include block-blob-accordion-item-content();

			.block-editor-inner-blocks .block-editor-block-list__layout {
				margin-top: -1 * $spacing;
				margin-bottom: -1 * $spacing;
			}

			.block-list-appender:empty {
				display: none;
			}
		}

		&:not(.has-child-selected) > .wp-block-blob-accordion > .block-editor-inner-blocks > .block-editor-block-list__layout > .block-list-appender {
			display: none;
		}
	}


}

