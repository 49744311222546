// core/pullquote

@mixin block-core-pullquote {
	position: relative;
	overflow: hidden;
	padding: 2em;
	background: transparent;
	border: 1px solid rgba(c(primary), 0.2);

	@include media(unstacked) {
		padding: 3em;
	}

	&:not(.is-style-solid-color) {
		background: transparent;
	}

	@include svg-bg-icon(quote, rgba(c(primary), 0.1), 20em) {

		@include position(auto, auto, -7em, -5.5em);
	}
}

@mixin block-core-pullquote-blockquote {
	margin: 0;
	padding: 0;
	border: 0;
}

@mixin block-core-pullquote-p {
	font-size: 1.5em;
	font-style: italic;
	line-height: inherit;
	text-align: center;
	color: c(tertiary);
}

@mixin block-core-pullquote-cite {
	display: block;
	text-align: center;
	margin-top: 1em;
	font-size: 0.8em;
	font-family: get($styles, heading font-family);
	font-weight: get($styles, heading font-weight);
	font-style: normal;
	text-transform: uppercase;
	color: c(tertiary);
}

@if $mode == theme or $mode == print {

	.wp-block-pullquote {

		@include block-core-pullquote();

		blockquote {

			@include block-core-pullquote-blockquote();
		}

		p {

			@include block-core-pullquote-p();
		}

		cite {

			@include block-core-pullquote-cite();
		}
	}
}

@if $mode == editor {

	figure.wp-block-pullquote {

		@include block-core-pullquote();

		padding: 2em !important;

		@include media(unstacked) {
			padding: 3em !important;
		}

		blockquote {

			@include block-core-pullquote-blockquote();
		}

		blockquote p {

			@include block-core-pullquote-p();
		}

		.wp-block-pullquote__citation {

			@include declare-map(get($styles, cite));
			@include block-core-pullquote-cite();
		}
	}
}
