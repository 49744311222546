// core/archives

@mixin block-core-archives {
	padding-#{script-direction()}: get($styles, indent);
}

@if $mode == theme or $mode == print {

	ul.wp-block-archives {

		@include block-core-archives();
	}
}

@if $mode == editor {

	// reset default styles for consistency

	.block-editor ul.wp-block-archives {

		@include block-core-archives();
	}
}
