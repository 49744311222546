// website main logo

@if $mode == theme or $mode == print {

	.site-logo {
		display: inline-flex;
		justify-content: center;
		align-items: center;
		text-transform: uppercase;

		a {
			display: inline-flex;
			justify-content: center;
			align-items: center;
			margin-right: -0.25 * $pad;
			margin-left: -0.25 * $pad;
			padding: 0.25 * $pad;
			color: c(base);
			transition-property: opacity;

			&:hover,
			&:focus {
				opacity: 0.75;
			}
		}

		img {
			max-width: 10em;

			@include media(big) {
				max-width: 13em;
			}
		}
	}
}
