// core/verse

@mixin block-core-verse {
	font-family: get($styles, font-family);
	font-size: get($styles, font-size);
}

@if $mode == theme or $mode == print {

	.wp-block-verse {

		@include block-core-verse();
	}
}

@if $mode == editor {

	[data-type="core/verse"] pre {

		@include block-core-verse();
	}
}
