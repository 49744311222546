// core/file

@mixin block-core-file {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

@mixin block-core-file-text-link {
	color: inherit;
	font-size: 1rem;
	text-decoration: inherit;
}

@mixin block-core-file-button-link {
	border-radius: get($styles, button border-radius, 0);
	font-size: get($styles, button font-size, inherit);

	&:hover,
	&:focus {
		box-shadow: get($styles, button hover box-shadow, none);
		color: get($styles, button hover color, get($styles, button color, inherit));
		opacity: get($styles, button hover opacity, 1);
		text-decoration: get($styles, button hover text-decoration, inherit);
	}

	&:visited {
		box-shadow: get($styles, button visited box-shadow, none);
		color: get($styles, button visited color, get($styles, button color, inherit));
		opacity: get($styles, button visited opacity, 1);
		text-decoration: get($styles, button visited text-decoration, inherit);
	}

	@include declare-map(remove(get($styles, button), border-radius, font-size));
}

@if $mode == theme or $mode == print {

	.wp-block-file {

		@include block-core-file();
	}

	.wp-block-file > a:first-child {

		@include block-core-file-text-link();
	}

	.wp-block-file > a:last-child {

		@include block-core-file-button-link();
	}
}

@if $mode == editor {

	.wp-block-file__content-wrapper {

		@include block-core-file();
	}

	.wp-block-file__textlink {

		@include block-core-file-text-link();
	}

	.wp-block-file .wp-block-file__button {

		@include block-core-file-button-link();
	}
}
