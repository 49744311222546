// core/columns

@mixin block-core-columns {
	display: flex;
	flex-wrap: wrap;

	@include media(unbroken) {
		flex-wrap: nowrap;
	}
}

@mixin block-core-column {

	@include media(unstacked broken) {
		flex-basis: calc(50% - #{$gap}) !important;
		flex-grow: 0;
		max-width: none;

		&:nth-child(2n) {
			margin-left: $gap;
		}
	}

	@include media(unbroken) {
		flex-basis: 100%;

		&:not(:first-child) {
			margin-left: $gap;
		}
	}

	@include media(untacked broken) {

		&:nth-child(n+3) {
			margin-top: $spacing;
		}
	}

	@include media(stacked) {
		flex-basis: 100% !important;

		&:not(:first-child) {
			margin-top: $spacing;
		}
	}
}


@if $mode == theme or $mode == print {

	.wp-block-columns,
	.blob-columns {
		margin-bottom: 0;

		@include block-core-columns();
	}

	.wp-block-column,
	.blob-columns > * {

		@include block-core-column();
	}

	@include media(unstacked broken) {

		.wp-block-columns.with-persistent-columns,
		.blob-columns.with-persistent-columns {
			flex-wrap: nowrap !important;
		}

		.wp-block-columns.with-persistent-columns > *,
		.blob-columns.with-persistent-columns .blob-column,
		.blob-columns.with-persistent-columns .wp-block-column {
			flex-basis: 100% !important;
			margin-top: 0 !important;
			margin-left: 0.5 * $gap !important;

			&:first-child {
				margin-left: 0 !important;
			}
		}

		.wp-block-columns.with-irresolute-columns > *,
		.blob-columns.with-irresolute-columns .blob-column,
		.blob-columns.with-irresolute-columns .wp-block-column {
			flex-basis: 100% !important;
			margin-top: $spacing !important;
			margin-left: 0 !important;
		}
	}

	.wp-block-column {

		> * {
			margin: 0;
		}

		> * + * {
			margin-top: $spacing;
			margin-bottom: 0;
		}
	}
}


@if $mode == editor {

	.wp-block-columns,
	.blob-columns {
		margin-bottom: $spacing;

		> .block-editor-inner-blocks > .block-editor-block-list__layout {

			@include block-core-columns();
		}
	}

	.wp-block-columns > .block-editor-inner-blocks > .block-editor-block-list__layout > [data-type="core/column"] > .block-core-columns > .block-editor-inner-blocks {
		margin-top: -1 * $spacing;
		margin-bottom: -1 * $spacing;
	}

	[data-type="core/column"],
	.blob-columns > .block-editor-inner-blocks > .block-editor-block-list__layout > * {

		@include block-core-column();
	}

	.blob-columns > .block-editor-inner-blocks > .block-editor-block-list__layout > * {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		align-items: stretch;

		> * {
			flex-basis: 100%;
		}
	}

	.wp-block[data-type="core/columns"]:not(.has-child-selected) .block-list-appender {
		display: none !important;
	}
}
