// blob/count-up

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-count-up {
		font-family: get($styles, heading font-family);
		font-weight: get($styles, heading font-weight);

		@include media(unstacked broken) {

			&.has-huge-font-size {
				font-size: 3em;
			}
		}

		@include media(unbroken) {

			&.has-huge-font-size {
				font-size: 4em;
			}
		}
	}
}

@if $mode == editor {

	[data-type="blob/count-up"]:not(.is-selected) :not(.has-prefix) .wp-block-blob-count-up__prefix,
	[data-type="blob/count-up"]:not(.is-selected) :not(.has-suffix) .wp-block-blob-count-up__suffix {
		display: none;
	}

	[data-type="blob/count-up"].is-selected .wp-block-blob-count-up__number {
		border-bottom: 1px dashed c(grey);
	}
}
