// screen reader text

@if $mode == theme {

	.screen-reader-text {
		position: absolute !important;
		clip: rect(1px, 1px, 1px, 1px);
		clip-path: inset(50%);
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		border: 0;
		overflow: hidden;
		word-wrap: normal !important;
		word-break: normal;

		&:focus {
			top: 0;
			right: 0;
			clip: auto !important;
			clip-path: none;
			display: block;
			width: auto;
			height: auto;
			z-index: 100;
			padding: 1em;
			background-color: #f1f1f1;
			color: c(base);
			text-decoration: none;
		}
	}
}

@if $mode == print {

	.screen-reader-text {
		display: none;
	}
}
