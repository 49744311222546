// wordpress admin bar styles

@if $mode == theme {

	#wpadminbar {

		ul,
		ol {
			overflow: visible;
		}
	}
}

@if $mode == print {

	#wpadminbar {
		display: none !important;
	}
}
