// blob/image-carousel

@if $mode == theme or $mode == print or $mode == editor {

	.wp-block-blob-image-carousel {

		img {
			display: block;
		}
	}
}
