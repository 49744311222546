@use "sass:string";

/// Use the padding trick to keep a container the same ratio in different screen sizes
/// @param {Number} $x [16] Width ratio
/// @param {Number} $y [9] Height raio
/// @param {String|List} $selector ["&"] The selector
/// @group helper

@mixin responsive-ratio($x: 16, $y: 9, $selector: "&") {
  $padding: $y/$x*100%;

  #{$selector} {
    position: relative;
    padding-top: $padding;

    @if string.index($selector, "::") {
      content: "";
      display: block;
      width: 100%;
    }
  }
}
