// core/list

@if $mode == theme or $mode == print {

	main {

		li + li,
		li ul {
			margin-top: 0.5 * $spacing;
		}

		ul.is-style-lower-latin {
			list-style-type: lower-latin;
		}

		ul.is-style-upper-latin {
			list-style-type: upper-latin;
		}

		ul.is-style-lower-roman {
			list-style-type: lower-roman;
		}

		ul.is-style-upper-roman {
			list-style-type: upper-roman;
		}
	}
}

@if $mode == editor {

	.editor-styles-wrapper ul {

		li + li,
		li ul {
			margin-top: 0.5 * $spacing;
		}

		&.is-style-lower-latin {
			list-style-type: lower-latin;
		}

		&.is-style-upper-latin {
			list-style-type: upper-latin;
		}

		&.is-style-lower-roman {
			list-style-type: lower-roman;
		}

		&.is-style-upper-roman {
			list-style-type: upper-roman;
		}
	}
}
